.navigation-buttons{
    position: absolute;
    bottom: 40px;
    left: 20px;
    z-index: 1;


    .hero-header-nav__button{
        background-color: $c-blue;
        height: 40px;
        border: 0px;
        border-radius: 0;
        width: auto;
        margin-left: 5px;
        margin-right: 5px;
        padding: 10px;
        font-size: 20px;
        color: #fff;
        outline: none;

        &:hover{
            transition: 200ms linear background-color;
            background-color: $c-green;
        }
    }
    .hero-header-nav__button.active{
        background-color: $c-green;
    }

    @include mq(small) {

        display: none;
    }
}