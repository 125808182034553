@use "sass:math";

$image-ratio: math.div(16, 9);

.hero-header-slider {
  position: relative;
  width: 100%;
  padding: 0;
  height: 100%;

  @include mq(small) {
    padding-bottom: 50px;
  }

  .hero-header-slider__cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-family: Calibri, sans-serif;
    font-weight: bold;

    .image-container{
      @include fixed-ratio($image-ratio, '> img');
      width: 100%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }



  .hero-header-slider__image-caption{
    position: absolute;
    font-size: 12px;
    color: $c-black-60;
    font-style: italic;
    margin: 3px 20px 0;
    display: block;
    height: 18px;
    font-family: Arial, sans-serif;
    font-weight: 400;
    bottom: -20px;
  }

  .slider-status {
    display: none;
    position: absolute;
    right: 75px;
    bottom: 5%;
    font-size: pxtorem(20);
    color: white;
    margin: 0;
    line-height: pxtorem(20);
    transform: translateY(-50%) translateX(50%);
    z-index: 1;
    font-weight: 700;
    right: 50%;

    @include mq(small) {
      display: block;
      right: 50%;
      bottom: 0px;
      color: $c-blue;
      transform: translateY(-50%) translateX(50%);
    }
  }

  .flickity-page-dots {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 40px;
    padding: 0;

    .dot {
      list-style: none;
      width: 0.75rem;
      height: 0.75rem;
      background: #fff;
      border-radius: 50%;
      transition: background-color 200ms;
      cursor: pointer;
      margin-left: 15px;

      &:hover {
        background: $c-green;
      }

      &.is-selected {
        background: #8cc054;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .slider-article{
    background-color: #ffffffbe;
    width: 400px;
    right: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding:20px ;

    .slider-article__title{
      font-size: 32px;
      color: $c-blue;
      text-align: left;
      margin: 0;
      line-height: 34px;
      @include mq(small) {
        font-size: 30px;
        line-height: 30px;
      }
    }
    .slider-article__text{
      font-size: 20px;
      line-height: 29px;
      font-weight: normal;
      margin: 0;
      margin-top: 15px;
      font-family: Arial, Helvetica, sans-serif;
      @include mq(small) {
        font-size: 17px;
        line-height: 26px;
      }
    }
    .slider-article__link{
      color: $c-blue;
      font-size: 20px;
      margin: 0px;
      margin-top: 12px;
      @include mq(small) {
        font-size: 17px;
      }
    }
    @include mq(small) {
      position: relative;
      top: 0%;
      transform: translateY(0%);
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 25px;
      width: auto;
      right: 0px;
    }
  }

  .flickity-prev-next-button {
    display: none;
    position: absolute;
    bottom: 50%;
    background: transparent;
    border: none;
    width: 40px;
    height: 40px;
    opacity: 1;
    transition: opacity 200ms;
    cursor: pointer;
    transform: translateY(50%);

    @include mq(small) {
      display: block;
      transform: translateY(0%);
    }

    &:hover:not(:disabled) .flickity-button-icon {
      fill: $c-green;
    }

    &:disabled {
      opacity: 0.4;
      cursor: auto;
    }

    &.previous {
      left: 25px;

      @include mq(small) {
        left: 10px;
        bottom: 0px;
      }
    }

    &.next {
      right: 25px;

      @include mq(small) {
        right: 10px;
        bottom: 0px;
      }
    }
  }

  .flickity-button-icon {
    fill: $c-white;
    transition: fill 200ms;

    @include mq(small) {
      fill: $c-blue;
    }
  }
}