.section-header {
    position: relative;
    margin: 0 20px 30px;
    display: flex;
    justify-content: center;

    @include mq(small) {
        margin-bottom: 15px;
    }

    &::before {
        content: '';
        display: block;
        left: 0;
        width: 100%;
        height: 4px;
        background: $c-green;
        position: absolute;
        top: calc(50% - 2px);

        @include mq(small) {
            height: 2px;
        }
    }

    .header-icon {
        position: relative;
        width: 100px;
        height: 100px;
        background: $c-green;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 50%;
        box-shadow: white 0 0 0 15px;
        z-index: 2;

        @include mq(small) {
            width: 50px;
            height: 50px;
            padding: 10px;
            box-shadow: white 0 0 0 10px;
        }
    }
}


.strategy > h2 {
    margin-top: 0;
    margin-bottom: 25px;
    text-align: center;
    color: $c-blue;

    @include mq(small) {
        margin-bottom: 20px;
    }
}