$image-ratio: 3*0.5;

.section-slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 40px 0 60px;

    @include mq(small) {
        margin-top: 20px;
    }

    .section-slider__cell {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;

        @include mq(small) {
            flex-direction: column;
        }
    }

    .section-slider__image-wrapper {
        width: 66%;

        @include mq(small) {
            display: flex;
            flex-direction: column-reverse;
            width: 100%;
        }
    }

    .section-slider__image {
        @include fixed-ratio($image-ratio, '> img');

        width: 100%;

        img {
            object-fit: cover;
            width: 100%;
        }
    }

    .section-slider__image-caption {
        position: relative;
        font-size: 12px;
        color: $c-black-60;
        font-style: italic;
        margin: 3px 20px 0;
        display: block;
        height: 18px;

        @include mq(small) {
            margin-top: 0;
        }
    }

    .section-slider__content {
        background-color: white;
        padding: 30px;
        width: 364px;
        margin-left: -5rem;
        margin-top: -18px;
        z-index: 1;

        @include mq(small) {
            width: calc(100% - 40px);
            margin: -3.2rem 0 0;
            padding: 20px;
        }
    }

    .section-slider__label {
        color: #253276;
        font-size: pxtorem(20);
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 14px;

        @include mq(small) {
            font-size: pxtorem(17);
        }
    }

    .section-slider__title {
        color: $c-black;
        margin: 0.25rem 0 1rem;
    }

    .section-slider__text {
        margin-bottom: 0;
    }

    .flickity-prev-next-button {
        position: absolute;
        top: calc(50% - 9px);
        padding: 13px;
        transform: translateY(-50%);
        background: $c-blue;
        border: none;
        width: 50px;
        height: 50px;
        opacity: 1;
        cursor: pointer;
        transition: opacity 200ms, background-color 200ms;

        @include mq(small) {
            top: calc((100vw / #{$image-ratio}) / 2);
        }

        &:hover {
            background: $c-green;
        }

        &:disabled {
            opacity: 0;
            pointer-events: none;
        }

        &.previous {
            left: 0;
        }

        &.next {
            right: 0;
        }
    }

    .flickity-button-icon {
        fill: #fff;
        width: 100%;
    }
}
