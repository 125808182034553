@use "sass:math";

@mixin fixed-ratio($ratio: 16/9, $selector: '> *') {
  $selector: unquote($selector);

  position: relative;

  &::after {
    content: '';
    display: block;
    height: 0;
    padding-bottom: math.div(1, $ratio) * 100%;
  }

  #{$selector} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
