.hero-slider {
    position: relative;
    background-color: #253276;
    width: 100%;
    overflow: hidden;
    margin: 0 0 80px 0;

    .hero-slider__cell {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        font-family: Calibri, sans-serif;
        font-weight: bold;
        padding: 40px 5rem 2rem;

        @include mq(small) {
            padding: 40px 20px;
        }
    }

    .hero-slider__cell-label {
        font-size: pxtorem(36);
        color: white;
        text-align: center;

        @include mq(small) {
            font-size: pxtorem(26);
        }
    }

    .hero-slider__cell-title {
        font-size: pxtorem(100);
        text-align: center;
        line-height: 1;
        color: $c-green;
        margin: 10px 0 20px;

        @include mq(small) {
            font-size: pxtorem(50);
        }
    }

    .argument-title{
        font-size: pxtorem(70);
        @include mq(small) {
            font-size: pxtorem(35);
        }
    }

    .hero-slider__cell-text {
        font-size: pxtorem(24);
        line-height: 1.2;
        text-align: center;
        color: white;
        margin: 0;
        max-width: 640px;

        @include mq(small) {
            font-size: pxtorem(20);
        }
    }

    .flickity-page-dots {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: 40px;
        padding: 0;

        .dot {
            list-style: none;
            width: 0.75rem;
            height: 0.75rem;
            background: #fff;
            border-radius: 50%;
            transition: background-color 200ms;
            cursor: pointer;
            margin-left: 15px;

            &:hover {
                background: $c-green;
            }

            &.is-selected {
                background: #8cc054;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .flickity-prev-next-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: transparent;
        border: none;
        width: 40px;
        height: 40px;
        opacity: 1;
        transition: opacity 200ms;
        cursor: pointer;

        @include mq(small) {
            top: auto;
            bottom: 3px;
        }

        &:hover:not(:disabled) .flickity-button-icon {
            fill: $c-green;
        }

        &:disabled {
            opacity: 0.4;
            cursor: auto;
        }

        &.previous {
            left: 20px;

            @include mq(small) {
                left: 10px;
            }
        }

        &.next {
            right: 20px;

            @include mq(small) {
                right: 10px;
            }
        }
    }

    .flickity-button-icon {
        fill: $c-white;
        transition: fill 200ms;
    }
}