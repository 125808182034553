@use "sass:math";

.hero-header {
  position: relative;
  margin-bottom: 30px;

  @include mq(small) {
    @include fixed-ratio(math.div(4, 3), "> img");
  }

  &::after {
    padding-bottom: 0px;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.hero-header__text {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
}

.hero-header__subtitle {
  font-family: Calibri, sans-serif;
  font-size: pxtorem(30);
  line-height: 1.2;
  color: white;
  margin: 15px 20px 30px;
  font-weight: 700;

  @include mq(small) {
    font-size: pxtorem(22);
    margin: 8px 20px 15px;
  }
}

.hero-header__title {
  color: white;
  margin: 0;
}

.hero-header__title span {
  padding: 5px 20px;
  background-color: rgba(140, 192, 84, 0.7);
  margin-bottom: 4px;
  display: inline;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;

  @include mq(small) {
    padding: 0 10px;
    margin-bottom: 2px;
  }
}
