@import "strip-unit";
@import "pxtorem";
@import "variables";
@import "media-queries";
@import "fixed-ratio";
@import "ie-only";
@import "normalize";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: Arial, sans-serif;
  scroll-behavior: smooth;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  line-height: 1.56;
  font-size: 100%;

  @include mq(small) {
    line-height: 1.4;
  }
}

.iframe-body {
  overflow: hidden;
}

iframe.iframe {
  width: 940px;
}

img {
  max-width: 100%;
  display: block;
}

a,
a:visited {
  color: $c-blue;
  text-decoration: inherit;
}

a:hover,
a:active {
  text-decoration: underline;
}

button {
  cursor: pointer;
}

h1 {
  font-family: Calibri, sans-serif;
  font-size: pxtorem(60);
  color: $c-blue;

  @include mq(small) {
    font-size: pxtorem(30);
  }
}

h2 {
  font-family: Calibri, sans-serif;
  font-size: pxtorem(36);
  color: $c-blue;
  text-align: center;

  @include mq(small) {
    font-size: pxtorem(26);
  }
}

h3 {
  font-family: Calibri, sans-serif;
  font-size: pxtorem(30);
  line-height: pxtorem(35);

  @include mq(small) {
    font-size: pxtorem(22);
    line-height: pxtorem(25);
  }
}

.page-wrapper {
  //margin: 0 -20px;
  //
  //@include mq(small) {
  //    margin: 0 -10px;
  //}

  td,
  th {
    border: none;
    padding: 0;
    margin: 0;
  }
}

.m20 {
  margin-left: 20px;
  margin-right: 20px;
}

.section-text {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 600px;

  &.is-big {
    font-size: 1.2rem;

    @include mq(small) {
      font-size: pxtorem(17);
    }
  }

  &.is-multi-column {
    column-count: 2;
    column-gap: 30px;

    @include mq(small) {
      column-count: 1;
    }
  }
}

.section-text__logo {
  float: right;
  margin: 20px;
}

@import "hero-header";
@import "hero-navigation-buttons";
@import "hero-header-slider";
@import "jump-marks";
@import "hero-slider";
@import "strategy-section";
@import "section-slider";
@import "transport-strategy";
@import "q-and-a";
