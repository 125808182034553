.qna__container {
    max-width: 730px;
    margin: 0 auto;

    @include mq(small) {
        margin: 0 10px;
    }
}

.qna__title {
    @include mq(small) {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.qna {
    margin: 15px 0;
    padding: 20px 30px;
    background-color: rgba(37, 50, 118, 0.1);
    overflow: hidden;
    box-sizing: border-box;
}

.qna__question {
    color: black;
    font-size: pxtorem(24);
    line-height: pxtorem(28);
    font-weight: 600;
    margin: 0;
    flex-grow: 1;

    @include mq(small) {
        font-size: pxtorem(20);
        line-height: pxtorem(24);
    }

    button {
        position: relative;
        margin: 0;
        padding: 0 40px 0 0;
        border: none;
        background: none;
        font-weight: 600;
        width: 100%;
        text-align: left;

        &:hover {
            &::before, &::after {
                background: $c-green;
            }
        }

        &::before, &::after {
            content: '';
            width: 20px;
            height: 3px;
            background: $c-blue;
            position: absolute;
            right: 0;
            top: 10px;
            transform: rotate(0deg);
            transition: transform 200ms, background-color 200ms;
        }

        &::after {
            transform: rotate(90deg);
        }

        &.is-expanded {
            &::before {
                transform: rotate(-180deg);
            }

            &::after {
                transform: rotate(0deg);
            }
        }
    }
}

.qna__answer {
    color: black;
    font-size: 16px;
    line-height: 25px;
    margin: 0;

    p {
        margin: 1rem 0 0;
    }
}


.question-box .toggle-btn {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 5px;
}

.question-box .toggle-btn span {
    position: absolute;
    top: 8px;
    display: block;
    width: 20px;
    height: 4px;
    background-color: #253276;
    transition: 200ms transform;
}

.question-box .toggle-btn:hover span {
    background-color: #8cc054;
}

.question-box .toggle-btn span:first-child {
    transform: rotate(90deg);
}

.question-box .toggle-btn span:last-child {
    transform: rotate(180deg);
}

.question-box.open .toggle-btn span {
    transform: rotate(0deg);
}