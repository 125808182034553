$c-green: #8CC054;
$c-blue: #253276;
$c-white: #fff;
$c-black: #000;
$c-black-60: rgba(0, 0, 0, 0.6);

$breakpoints: (
  xsmall: 600px,
  small: 767px,
  medium: 992px,
  large: 1024px,
  xlarge: 1240px,
  max-width: 1440px
);