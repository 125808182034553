.transport-strategy {
  background-color: $c-blue;
  color: $c-white;
  padding: 40px 0;
  margin-bottom: 80px;

  @include mq(small) {
    padding: 30px 0;
    margin-bottom: 60px;
  }

  .transport-strategy__title {
    color: $c-white;

    @include mq(small) {
      margin-top: 0;
      font-size: pxtorem(26);
    }
  }

  .transport-strategy__icon-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 150px;

    @include mq(small) {
      margin: 20px;
    }
  }

  .transport-strategy__icon-button {
    flex: 0 1 100px;
    height: 100%;
    width: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-color: $c-blue;
    border: 4px solid $c-white;
    cursor: pointer;
    transition: background-color 100ms, border-color 100ms;

    @include mq(small) {
      flex: 0 1 50px;
      width: 50px;
      border: 2px solid $c-white;
      padding: 7px;
    }

    svg {
      display: block;
      position: relative;
      flex-grow: 1;
      fill: $c-white;
      transition: fill 100ms;
      width: 100%;

      @include ie-only {
        height: 100px;
      }
    }

    &:hover,
    &:focus {
      border-color: $c-green;

      svg {
        fill: $c-green;
      }
    }

    &:active,
    &.active {
      background-color: $c-green;
      border-color: $c-green;

      svg {
        fill: $c-white;
      }
    }
  }

  .transport-strategy__icon-line {
    display: block;
    min-width: 10px;
    height: 4px;
    background-color: $c-white;
    margin: 8px;
    flex-grow: 1.5;

    @include mq(small) {
      height: 2px;
    }
  }

  .transport-strategy__slider {
    overflow: hidden;
  }

  .strategy-slide {
    display: flex;
    width: 100%;
    padding: 0 100px;

    @include mq(small) {
      flex-direction: column;
      padding: 0 20px;
    }
  }

  .strategy-slide__title {
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: bold;
    color: $c-white;

    @include mq(small) {
      font-size: pxtorem(28);
    }
  }

  .strategy-slide__inline-start {
    min-width: 50%;
    flex: 1 0;
  }

  .strategy-slide__inline-end {
    flex: 1 1 50%;
    margin-left: 40px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    @include mq(small) {
      margin-left: 0;
      margin-top: 20px;
    }
  }

  .strategy-diagram {
    margin: 0;
    padding: 0;
    border-collapse: separate;
    border-spacing: 0 10px;
  }

  .strategy-diagram__row {
    position: relative;
    font-size: pxtorem(20);
    font-weight: bold;

    &:last-child {
      .strategy-diagram__value {
        background-color: $c-green;
        color: $c-white;
      }
    }
  }

  .strategy-diagram__year {
    color: $c-white;
    padding-right: 22px;
    vertical-align: middle;

    @include mq(small) {
      font-size: pxtorem(17);
    }
  }

  .strategy-diagram__data {
    width: 100%;
  }

  .strategy-diagram__value {
    display: block;
    padding: 9px;
    background-color: $c-white;
    color: $c-blue;
    text-align: center;

    @include mq(small) {
      padding: 7px;
      font-size: pxtorem(17);
    }
  }

  .strategy-slide__teaser-number {
    font-family: Calibri, sans-serif;
    font-size: pxtorem(100);
    line-height: 1;
    color: $c-green;
    margin-bottom: 5px;
    font-weight: bold;
    text-align: right;

    @include mq(small) {
      font-size: pxtorem(50);
    }
  }

  .strategy-slide__description {
    font-size: pxtorem(17);
    line-height: 1.2;
    text-align: right;
    font-weight: bold;
  }
}
