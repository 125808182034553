.jump-marks {
  margin: 0 0 30px;
}

.jump-marks__mobile {
  display: none;
  @include mq(small) {
    display: flex;
    border: none;
    background: $c-white;
    width: 100%;
    border: 2px solid $c-blue;
    border-bottom: none;
    margin-top: 10px;
  }

  .current-section {
    width: 100%;
    text-align: start;
    color: $c-blue;
    font-weight: bold;
  }

  .jump-marks__icon {
    align-self: center;
    height: 24px;

    .jump-marks__arrow {
      height: 24px;
      fill: $c-blue;
      transform: rotate(90deg);

      &.active {
        transform: rotate(-90deg);
      }
    }
  }

  &:hover {
    background: $c-blue;

    .current-section {
      color: $c-white;
    }

    .jump-marks__arrow {
      fill: $c-white;
    }
  }
}

.jump-marks--sticky {
  position: relative;
  top: 0px;
  z-index: 10;
  behavior: smooth;
  background: $c-white;
  width: 100%;
  padding-top: 16px;
  will-change: transform;

  @include mq(small) {
    margin-left: 10px;
    margin-right: 10px;
    background: transparent;
    width: auto;
    padding-top: 10px;
  }
}

.jump-marks__list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  justify-content: center;

  &.active {
    @include mq(small) {
      padding: 10px;
      padding-top: 0px;
      height: auto;
    }
  }

  @include mq(small) {
    flex-direction: column;
    border: 2px solid $c-blue;
    border-top: none;
    flex-wrap: nowrap;
    height: 0px;
    background: $c-white;
    transition: all 200ms ease;
    position: absolute;
    width: 100%;
    overflow: hidden;
  }
}

.jump-marks__list-item {
  margin: 8px;
  font-size: 1rem;
  background-color: $c-white;
  transition: background-color 150ms;

  .jump-marks__icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;

    svg {
      fill: $c-blue;
      transition: fill 150ms;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    padding: 0.35rem 0.5rem;
    border: 2px solid $c-blue;
    font-weight: bold;
    color: $c-blue;
    transition: color 150ms;

    @include mq(small) {
      border: none;
      border-bottom: 2px solid $c-blue;
      justify-content: start;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  &:hover {
    background-color: $c-blue;

    svg {
      fill: $c-white;
    }

    a {
      text-decoration: none;
      color: $c-white;
    }
  }
  @include mq(small) {
    margin: 0px;
  }
}
